<template>
    <!-- 用户信息 -->
    <div class="user" v-if="displayFlag">
        <el-row>
            <el-col :span="24" style="margin-top: 30px">
                <el-form size="small" :inline="true" :model="formInline" class="demo-form-inline"
                    style="display: flex; justify-content: space-between">
                    <el-col :span="16">
                        <el-form-item label="名称及编号">
                            <el-input v-model="formInline.input" placeholder="请输入内容"></el-input>
                        </el-form-item>
                        <span style="margin-right: 10px; color: #666; font-size: 14px">时间</span>
                        <el-date-picker size="small" style="width: 150px" v-model="value1" type="date"
                            format="YYYY-MM-DD" value-format="YYYYMMDD" placeholder="选择日期"></el-date-picker>
                        <span style="margin: 0 10px; color: #d0d0d0">-</span>
                        <el-date-picker size="small" style="width: 150px" v-model="value2" type="date"
                            format="YYYY-MM-DD" value-format="YYYYMMDD" placeholder="选择日期"></el-date-picker>
                        <el-form-item label="状态" style="margin-left: 10px">
                            <el-select style="width: 120px" v-model="formInline.region" placeholder="状态">
                                <el-option label="所有" value="2"></el-option>
                                <el-option label="正常" value="0"></el-option>
                                <el-option label="停用" value="1"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="账号类型">
                            <el-select style="width: 120px" v-model="formInline.user" placeholder="类型">
                                <el-option label="所有" value="0"></el-option>
                                <el-option label="企业" value="1"></el-option>
                                <el-option label="个人" value="2"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-button style="margin-left: 10px" @click="init" type="primary" size="small">查询</el-button>
                    </el-col>
                    <el-col :span="4" style="display: flex; justify-content: space-between">
                        <span></span>
                        <router-link to="/userAdd" custom v-slot="{ navigate }">
                            <div @click="navigate" @keypress.enter="navigate" role="link" class="koaz dianji">新建</div>
                        </router-link>
                    </el-col>
                </el-form>
            </el-col>
            <div style="width: 100%; margin-top: 20px">
                <el-table :header-cell-style="{ background: '#F2F2F2' }" :data="tableData" border
                    style="width: 100%; text-align: center">
                    <el-table-column align="center" width="80" type="index" label="序号"></el-table-column>

                    <el-table-column align="center" prop="e_mx_id" label="用户ID"></el-table-column>

                    <el-table-column align="center" prop="u_username" label="账号"></el-table-column>

                    <el-table-column align="center" prop="user_count" label="账户数"></el-table-column>

                    <el-table-column align="center" prop="u_mobile" label="绑定手机号"></el-table-column>

                    <el-table-column align="center" prop="e_name" label="企业名称"></el-table-column>

                    <el-table-column align="center" prop="combo_name" label="服务类型"></el-table-column>

                    <el-table-column align="center" label="状态">
                        <template #default="scope">
                            <div v-if="scope.row.e_status == 1">停用</div>
                            <div v-if="scope.row.e_status != 1">正常</div>
                        </template>
                    </el-table-column>

                    <el-table-column align="center" prop="u_createtime" label="注册时间">
                        <template #default="scope">
                            <div>{{ formatDate(scope.row.u_createtime) }}</div>
                        </template>
                    </el-table-column>

                    <el-table-column align="center" label="操作">
                        <template #default="scope">
                            <el-button type="text" size="small" @click="editClick(scope.row.u_id, scope.row.e_id)">编辑
                            </el-button>
                            <el-button type="text" size="small"
                                @click="disableClick(scope.row.e_id, scope.row.e_status == 0 ? 1 : 0)">{{
                                scope.row.e_status == 0 ? '停用' : '启用' }}</el-button>
                            <!-- <el-button type="text" size="small" @click="deleteClick">删除</el-button> -->
                        </template>
                    </el-table-column>
                </el-table>
                <div style="text-align: center; margin-top: 25px">
                    <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
                        :current-page="page.page" :page-sizes="[10, 15, 30, 35]" :page-size="page.pageSize"
                        layout="total, sizes, prev, pager, next, jumper" :total="page.totalItems">
                    </el-pagination>
                </div>
            </div>
        </el-row>
    </div>
    <div class="compileuser" style="background: #ffffff" v-if="!displayFlag">
        <header style="height: 80px; padding-right: 300px; text-align: center; line-height: 80px">
            <div style="color: #4d4d4d; font-size: 24px">{{ form.name }}</div>
        </header>
        <div style="display: flex">
            <div style="
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    width: calc(100% - 300px);
                    border-top: 1px solid #ebeff0;
                ">
                <el-form ref="form" :model="form" size="small" label-width="130px" style="width: 70%" class="classForm"
                    label-position="right">
                    <el-form-item label="企业名称：" class="form-item-class">
                        <input type="text" disabled style="background: #ffffff" v-model="form.name" />
                    </el-form-item>
                    <el-form-item label="登陆账号：" class="form-item-class">
                        <input type="text" disabled style="background: #ffffff" v-model="form.username" />
                    </el-form-item>
                    <el-form-item label="登陆密码：" class="form-item-class">
                        <input type="text" disabled style="background: #ffffff" v-model="form.password" />
                    </el-form-item>
                    <el-form-item label="企业激活码：" class="form-item-class">
                        <input type="text" disabled style="background: #ffffff" v-model="form.co_code" />
                    </el-form-item>
                    <el-form-item label="绑定手机号：" class="form-item-class">
                        <input type="text" disabled style="background: #ffffff" v-model="form.e_mobel" />
                    </el-form-item>
                    <el-form-item label="账号类型：" class="form-item-class">
                        <input type="text" disabled style="background: #ffffff" v-model="form.e_account_type" />
                    </el-form-item>
                    <el-form-item label="地区：" class="form-item-class">
                        <input type="text" disabled style="background: #ffffff" v-model="form.region" />
                    </el-form-item>
                    <el-form-item label="法人：" class="form-item-class">
                        <input type="text" disabled style="background: #ffffff" v-model="form.e_legal_person" />
                    </el-form-item>
                    <el-form-item label="法人身份证：" class="form-item-class">
                        <input type="text" disabled style="background: #ffffff" v-model="form.e_legal_person_id" />
                    </el-form-item>
                    <el-form-item label="企业对接员：" class="form-item-class">
                        <input type="text" disabled style="background: #ffffff" v-model="form.e_dj_people" />
                    </el-form-item>
                    <el-form-item label="对接人电话：" class="form-item-class">
                        <input type="text" disabled style="background: #ffffff" v-model="form.e_dj_people_phone" />
                    </el-form-item>
                    <el-form-item label="企业地址：" class="form-item-class">
                        <input type="text" disabled style="background: #ffffff" v-model="form.gs_address" />
                    </el-form-item>
                    <el-form-item label="开户银行：" class="form-item-class">
                        <input type="text" disabled style="background: #ffffff" v-model="form.bank_of_deposit" />
                    </el-form-item>
                    <el-form-item label="开户银行所在地：" class="form-item-class">
                        <input type="text" disabled style="background: #ffffff" v-model="form.bank_of_deposit_addr" />
                    </el-form-item>
                    <el-form-item label="银行卡账户：" class="form-item-class">
                        <input type="text" disabled style="background: #ffffff" v-model="form.bank_card_account" />
                    </el-form-item>
                    <el-form-item label="支付宝账户：" class="form-item-class">
                        <input type="text" disabled style="background: #ffffff" v-model="form.zfb_account" />
                    </el-form-item>
                    <el-form-item label="企业税号：" class="form-item-class">
                        <input type="text" disabled style="background: #ffffff" v-model="form.e_tax_number" />
                    </el-form-item>
                    <el-form-item label="旗下品牌：" class="form-item-class">
                        <input type="text" disabled style="background: #ffffff" v-model="form.name" />
                    </el-form-item>
                    <el-form-item label="企业服务类型：" class="form-item-class">
                        <input type="text" disabled style="background: #ffffff" v-model="form.combo_name" />
                        <router-link to="/package" custom v-slot="{ navigate }">
                            <span @click="navigate" @keypress.enter="navigate" role="link"
                                style="color: #45a6ff; font-size: 12px">管理其他服务</span>
                        </router-link>
                    </el-form-item>
                    <el-form-item label="服务时间：" class="form-item-class">
                        <input type="text" disabled style="background: #ffffff" v-model="form.package_time" />
                    </el-form-item>
                    <el-form-item label="企业账号数：" class="form-item-class">
                        <input type="text" disabled style="background: #ffffff" v-model="form.e_account_num" />
                    </el-form-item>
                    <el-form-item label="企业方案数：" class="form-item-class">
                        <input type="text" disabled style="background: #ffffff" v-model="form.e_programme" />
                    </el-form-item>
                    <el-form-item label="企业构件数：" class="form-item-class">
                        <input type="text" disabled style="background: #ffffff" v-model="form.e_component_num" />
                    </el-form-item>
                    <el-form-item label="企业合同数：" class="form-item-class">
                        <input type="text" disabled style="background: #ffffff" v-model="form.e_contract_num" />
                    </el-form-item>
                    <el-form-item label="企业标签：" class="form-item-class">
                        <input type="text" disabled style="background: #ffffff" v-model="form.e_label" />
                    </el-form-item>
                    <el-form-item label="企业账户状态：" class="form-item-class">
                        <input type="text" disabled style="background: #ffffff" v-model="form.e_status" />
                    </el-form-item>
                    <el-form-item label="企业来源：" class="form-item-class">
                        <input type="text" disabled style="background: #ffffff" v-model="form.e_source" />
                    </el-form-item>
                    <el-form-item label="最后登录IP地区：" class="form-item-class">
                        <input type="text" disabled style="background: #ffffff" v-model="form.l_ip1" />
                    </el-form-item>
                    <el-form-item label="IP记录：" class="form-item-class">
                        <input type="text" disabled style="background: #ffffff" v-model="form.l_ip2" />
                    </el-form-item>
                    <el-form-item label="常用设备：" class="form-item-class">
                        <input type="text" disabled style="background: #ffffff" v-model="form.l_device" />
                    </el-form-item>
                    <el-form-item label="企业说明：" class="form-item-class">
                        <input type="text" disabled style="background: #ffffff" v-model="form.e_remark" />
                    </el-form-item>
                    <el-form-item label="身份信息：" class="form-item-class">
                        <img src="" alt="" />
                        <img src="" alt="" />
                    </el-form-item>
                    <el-form-item label="企业营业执照：" class="form-item-class">
                        <img src="" alt="" />
                        <img src="" alt="" />
                    </el-form-item>
                    <el-form-item label="备注说明：" class="form-item-class">
                        <input type="text" disabled style="background: #ffffff" v-model="form.u_e_remake" />
                    </el-form-item>
                    <div style="display: flex; align-items: center; justify-content: center; margin: 60px 0">
                        <!-- <el-button size="small" type="primary" @click="onSubmit('save')" style="margin-right:100px;">保存</el-button> -->
                        <el-button size="small" @click="displayFlag = true">返回</el-button>
                    </div>
                </el-form>
            </div>
        </div>
    </div>
</template>
<script>
import { list, upd_status, e_u_details } from '../../common/js/setting';
import $ from 'jquery';
export default {
    data() {
        return {
            //  用户信息数据
            displayFlag: true,
            // 搜索数据
            value1: '',
            value2: '',
            formInline: {
                user: '0',
                region: '2',
                input: ''
            },
            // 表格数据
            tableData: [],
            // 分页
            page: {
                page: 1,
                pageSize: 10,
                totalItems: 0
            },
            //
            ruleForm: {
                phone: '',
                region: '',
                name: ''
            },
            //  编辑数据
            form: [''],
            // 权限操作集合
            permissionsAction: {}
        };
    },
    methods: {
        // 编辑
        editClick(u_id, e_id) {
            this.displayFlag = false;
            // console.log(u_id, e_id);
            e_u_details({ u_id: u_id, e_id: e_id }).then(res => {
                this.form = res.result;
                // if(res.code == 200){
                //     this.$message.success("停用成功");
                //     list({page:this.page.page,pageSize:this.page.pageSize}).then(res=>{
                //         this.tableData = res.result
                //         this.page.totalItems = res.count;
                //     })
                // }else{
                //     this.$message.error("停用失败")
                // }
            });
        },
        // 删除
        // deleteClick(id){
        //     upd_status({e_id:id}).then(res =>{
        //         if(res.code == 200){
        //             this.$message.success(res.msg);
        //             list({page:this.page.page,pageSize:this.page.pageSize}).then(res=>{
        //                 this.tableData = res.result
        //                 this.page.totalItems = res.count;;
        //             })
        //         }else{
        //             this.$message.error(res.msg)
        //         }
        //     })
        // },
        // 停用
        disableClick(id, value) {
            upd_status({ e_id: id, e_status: value }).then(res => {
                if (res.code == 200) {
                    this.$message.success(res.msg);
                    list({ page: this.page.page, pageSize: this.page.pageSize }).then(res => {
                        this.tableData = res.result;
                        this.page.totalItems = res.count;
                    });
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
        // 分页
        handleSizeChange(size) {
            this.page.pageSize = size;
            this.init();
        },
        handleCurrentChange(page) {
            this.page.page = page;
            this.init();
        },
        init() {
            let dt = {
                search: this.formInline.input,
                e_status: this.formInline.region == 2 ? '' : this.formInline.region,
                e_account_type: this.formInline.user == 0 ? '' : this.formInline.user,
                start_time: this.value1,
                end_time: this.value2,
                page: this.page.page,
                pageSize: this.page.pageSize
            };
            list(dt).then(res => {
                this.tableData = res.result;
                this.page.totalItems = res.count;
            });
        },
        formatDate(value) {
            if (value) {
                let date = new Date(parseInt(value * 1000));
                let Y = date.getFullYear() + '-';
                let M = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) + '-' : date.getMonth() + 1 + '-';
                let D = date.getDate() < 10 ? '0' + date.getDate() + ' ' : date.getDate() + ' ';
                let h = date.getHours() < 10 ? '0' + date.getHours() + ':' : date.getHours() + ':';
                let m = date.getMinutes() < 10 ? '0' + date.getMinutes() + ':' : date.getMinutes() + ':';
                let s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
                return Y + M + D + h + m + s;
            }
        },
        // chaxuna(){
        //     console.log(this.formInline.input);
        //     console.log(this.formInline.region);
        //     console.log(this.formInline.user);
        //     console.log(value1);
        //     console.log(value2);
        //     console.log(this.page.page);
        //     console.log(this.page.pageSize);
        //     this.dt = {
        //         search: this.formInline.input,
        //         e_status: this.formInline.region == 2?'':this.formInline.region,
        //         e_account_type: this.formInline.user == 0?'':this.formInline.user,
        //         start_time: value1,
        //         end_time: value2,
        //         page: this.page.page,
        //         pageSize: this.page.pageSize,
        //     }
        //     list(this.dt).then(res =>{
        //         this.tableData = res.result
        //         this.page.totalItems = res.count;
        //     })
        // },
        changeDateFormat(cellval, time = 1) {
            if (cellval != null) {
                var dateVal = cellval * 1000 + '';
                var date = new Date(parseInt(dateVal.replace('/Date(', '').replace(')/', ''), 10));
                var month = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1;
                var currentDate = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
                if (time) {
                    var hours = date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
                    var minutes = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
                    var seconds = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
                    return (
                        date.getFullYear() +
                        '-' +
                        month +
                        '-' +
                        currentDate +
                        ' ' +
                        hours +
                        ':' +
                        minutes +
                        ':' +
                        seconds
                    );
                }
                return date.getFullYear() + '-' + month + '-' + currentDate;
            }
        }
    },
    mounted() {
        // list({page:this.page.page,pageSize:this.page.pageSize}).then(res=>{
        //     this.tableData = res.result
        //     this.page.totalItems = res.count;
        //     console.log(res);
        // })
    }
};
</script>
<style>
.form-item-class {
    display: flex;
    align-items: center;
    height: 55px;
    margin: 0 !important;
    border-bottom: 1px solid #ebeff0;
    color: #cdcef3;
}

.user .koaz {
    width: 100px;
    height: 30px;
    border-radius: 4px;
    background-color: #1790ff;
    color: white;
    text-align: center;
    line-height: 30px;
    font-size: 14px;
}

.user .dianji {
    cursor: pointer;
}

.compileuser .classForm input {
    width: 300px;
    border: none;
    outline: none;
    color: rgb(103, 103, 103);
    font-size: 12px;
}

.compileuser .el-form-item__label {
    color: #9fa2e9;
}
</style>